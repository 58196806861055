.carousel.carousel-slider {
    overflow: inherit;
}

.carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
    background-color: transparent;
    bottom: -604px;
    left: 1px;
}

.carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
    background-color: transparent;
    top: -604px;
    right: 1px;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
    opacity: 1;
}


.gold-carousel .control-next.control-arrow:before {
    /* color: #EF7E24; */
    content: '';
    border: solid #EF7E24;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.gold-carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #EF7E24;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.palladium-carousel .control-next.control-arrow:before {
    /* color: #EF7E24; */
    content: '';
    border: solid #C37A64;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.palladium-carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #C37A64;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}

.diamond-carousel .control-next.control-arrow:before {
    /* color: #EF7E24; */
    content: '';
    border: solid #48B2D1;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.diamond-carousel .control-prev.control-arrow:before {
    content: '';
    border: solid #48B2D1;
    border-width: 0 8px 8px 0;
    display: inline-block;
    padding: 14px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}